import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  NbButtonModule, NbCardModule,
  NbDialogModule,
  NbInputModule, NbPopoverModule,
  NbToastrModule
} from "@nebular/theme";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { RatingModule } from "ngx-bootstrap/rating";
import { SortableModule } from "ngx-bootstrap/sortable";
import { NgxMaskModule } from "ngx-mask";
import { ToastrModule } from "ngx-toastr";
import { environment } from '../environments/environment';
import { CoreModule } from "./@core/core.module";
import { ThemeModule } from "./@theme/theme.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MessagingService } from './messaging.service';
import { DeliveryFeedbackComponent } from "./shared/delivery-feedback/delivery-feedback.component";
import { EmailVerifyComponent } from "./shared/dialogs/email-verify/email-verify.component";
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
 

@NgModule({
  declarations: [AppComponent, EmailVerifyComponent, DeliveryFeedbackComponent, ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
     NgxMaterialTimepickerModule,
    PaginationModule.forRoot(),
    AppRoutingModule,
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    ToastrModule.forRoot(),
    NbDialogModule.forRoot(),
    RatingModule.forRoot(),
    NbPopoverModule,
    AngularFireModule.initializeApp(environment.firebase),
    NbCardModule,
    FormsModule,
    NbButtonModule,
    NbInputModule,
    ReactiveFormsModule,
    FormsModule,
    NbToastrModule.forRoot(),
    SortableModule.forRoot(),
    NgxMaskModule.forRoot(),
    
  ],
  providers: [MessagingService,AngularFireMessaging],
  bootstrap: [AppComponent],
})
export class AppModule {}
