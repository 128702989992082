<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <!-- <a class="logo" href="#" (click)="navigateHome()"><img src="../../../../assets/logo.svg" height="45px" /></a> -->
    <!-- <a class="logo" href="#" (click)="navigateHome()" style="font-weight: 700; font-size: 28px;">RapidCab</a> -->
    <a class="logo" href="#" (click)="navigateHome()">
      <img src="../../../../assets/logo-png.png" height="45px" alt="">
    </a>


  </div>
</div>

<div class="header-container header-user">
  <nb-actions size="small">
    <!-- <nb-action class="control-item" icon="bell-outline" (click)="navigateNoti()"></nb-action> -->
    <!-- <nb-action class="control-item" [ngClass]="isActiveURL ?  'active-bell' : ''" icon="bell-outline"
     >
    </nb-action> -->
    <!-- <nb-action class="control-item" [ngClass]="isActiveURL ?  'active-bell' : ''" icon="bell-outline" [badgeText]="' '"
      badgeStatus="danger" badgePosition="top right">
    </nb-action> -->


    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <!-- <nb-user
        [name]="user?.name"
        [picture]="getUserImage(user?.profile_image)"
      >
      </nb-user> -->



      <div class="dropdown d-flex justify-content-center align-items-center gap-2">
        <div>
          <nb-select placeholder="Select Status" [(selected)]="selectedItem" (selectedChange)="selectLanguage($event)"
            status="warning">
            <nb-option value="en">English</nb-option>
            <nb-option value="de">Deutsch </nb-option>
            <nb-option value="fr">Français</nb-option>
            <nb-option value="hu">Magyar </nb-option>
            <nb-option value="pl">Polski</nb-option>
            <nb-option value="sk">slovenský </nb-option>
            <nb-option value="sl">Slovenščina</nb-option>
            <nb-option value="bg">български </nb-option>
            <nb-option value="et">eesti keel</nb-option>
            <nb-option value="lv">latviski </nb-option>
            <nb-option value="lt">lietuvių</nb-option>
            <nb-option value="ro">Română </nb-option>
            <nb-option value="cs">čeština</nb-option>
          </nb-select>
        </div>
        <!-- <nb-user [name]="user?.name" [picture]="getUserImage(user?.profile_pic)" d="dropdownMenu2" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false" ></nb-user>
        <img style="display: none;" [src]="getUserImage(user?.profile_pic)" (error)="user.profile_pic=''"> -->
        <!-- <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        Dropdown
      </button> -->
        <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenu2">

        <a (click)="navigateToProfile()" class="dropdown-item text-decoration-none p-dropdown" type="button">Profile</a>
        <a (click)="navigateToChangePwd()" class="dropdown-item text-decoration-none p-dropdown" type="button">Change Password</a>
      </div> -->
        <p class="fw-bold">{{languageJson[languageKey]?.header?.admin_panel}}</p>
      </div>

    </nb-action>
  </nb-actions>

</div>