import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { API_TYPE } from '../../constants';
import { CommonService } from '../../service/common.service';

@Component({
  selector: 'ngx-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.scss']
})
export class EmailVerifyComponent implements OnInit {
  title: string;
  @Output() onCloseDialog = new EventEmitter();
  @Output() onVerify = new EventEmitter();
  userData: any;

  constructor(private dialogRef: NbDialogRef<any>,
    private commonService: CommonService,
    private toastrService: ToastrService) { }

  ngOnInit(): void {
  }

  Verified() {
    //this.onVerify.emit(true);
    this.GetProfile();

  }

  ResendLink() {
    let params = {
      name: this.userData.name,
      email: this.userData.email,
      type: 1
    }
    this.commonService.httpRequest(API_TYPE.GET, '/resend/email', params)
      .subscribe(res => { }, error => { });
  }

  GetProfile() {
    this.commonService.httpRequest(API_TYPE.GET, '/profile', '')
      .subscribe(res => {
        if (!res.is_email_verified) {
          let msg = "Email not verified!"
          this.showToast(msg);
          // this.closeDialog(); // must remove this line
        } else {
          let msg = "Email verified!"
          this.showToast(msg);
          this.closeDialog();
        }
      }, error => { });
  }

  showToast(msg) {
    this.toastrService.show(msg);
  }

  closeDialog() {
    // this.onCloseDialog.emit(true);
    this.dialogRef.close();
  }

  LogOut() {
    this.commonService.logOut(1);
  }

}
