import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { language } from '../../../shared/language';
import { Subscription } from 'rxjs';
import { CommonService } from '../../../shared/service/common.service';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  languageKey: any;
  private languageKeySubscription: Subscription;
  languageJson = language
  constructor(private route: Router, private cs: CommonService) {
  }
  goTo() {
    this.route.navigate(['admin/dashboard'])
  }
  ngOnInit() {
    this.languageKeySubscription = this.cs.getLanguageKey().subscribe(language => {
      this.languageKey = language;
      console.log('Updated sdfghjkl;', this.languageKey);
    });
  }
  faceboook() {
    window.open(
      "https://www.facebook.com/", "_blank");
  }
  instagram() {
    window.open(
      "https://www.instagram.com/accounts/login/", "_blank");
  }
  linkedin() {
    window.open(
      "https://www.linkedin.com/login", "_blank");
  }
  email() {
    window.open(
      "https://accounts.google.com/signup/v2/webcreateaccount?hl=en&flowName=GlifWebSignIn&flowEntry=SignUp", "_blank"
    );
  }
  // email(){
  //   window.open(
  //     "mailto:admin@gmail.com", "_blank");
  // let  w=window.open('','_blank','',true);
  // w.location.href='mailto:mailto:admin@thetraxi.com';
  // w.focus();
  // }
  // <a href="mailto:admin@gmail.com" target="_blank">
}
