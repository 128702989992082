import { Component, OnInit } from "@angular/core";

import { NavigationEnd, Router } from "@angular/router";
import { MessagingService } from './messaging.service';
import { CommonService } from "./shared/service/common.service";

@Component({
  selector: "ngx-app",
  template: "<router-outlet></router-outlet>",
})
export class AppComponent implements OnInit {

  constructor(private messagingService: MessagingService,
    private router: Router,
    private commonService: CommonService) {

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.commonService.updateActiveBell.next(true);
      }
    });
  }

  ngOnInit() {
    this.messagingService.requestPermission();
  }
}
