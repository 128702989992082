<nb-card>
    <nb-card-header>
         <h6 class="font-default fw-600 d-flex align-items-center">
             <img src="../../../assets/img/delivery_successful.png" height="18px" class="pr-2">Delivery Successful</h6>
    </nb-card-header>
    <nb-card-body class="bg-secondary">
         <img src="../../../assets/img/logo_header.png" class="d-block mx-auto mb-3">
        <p class="font-small fw-600  px-0 text-center">How was your delivery today?</p>
        <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"
        class="d-block text-center mb-2"
        (onHover)="hoveringOver($event)" (onLeave)="resetStar()"
        [titles]="['one','two','three']"></rating>
        <textarea nbInput fullWidth placeholder="Please provide feedback"></textarea>

      <button  class="btn btn-primary mx-auto btn-block px-4 mt-2" >SUBMIT</button>
    </nb-card-body>
  </nb-card>
