import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { NbAuthService } from "@nebular/auth";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private authService: NbAuthService, private router: Router) {}

  canActivate() {
    let userSession = JSON.parse(localStorage.getItem("admin-session"));
    if (userSession && userSession["token"]) {
      return true;
    } else {
      this.router.navigate(["login"]);
    }
  }
}
