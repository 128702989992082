<span class="created-by">
    {{languageJson[languageKey]?.common?.footer?.copyright}} &copy; 2024 <b><a (click)="goTo()">Euroride</a></b>
    {{languageJson[languageKey]?.common?.footer?.all_rights_reserved}}
  </span>
  <!-- <div class="socials">

  <a (click)="faceboook()">
      <img src="../../../assets/facebook.svg" height="25px">
  </a>

  <a (click)="instagram()">
      <img src="../../../assets/instagram.svg" height="25px">
  </a>

  <a (click)="linkedin()">
      <img src="../../../assets/linkedin.svg" height="25px">
  </a>

  <a (click)="email()">
      <img src="../../../assets/email.svg" height="25px">
  </a>
</div> -->