import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";
import { Subject, Subscription } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { UserData } from "../../../@core/data/users";
import { LayoutService } from "../../../@core/utils";
import { API_TYPE, MEDIA_URL } from "../../../shared/constants";

import { CommonService } from "../../../shared/service/common.service";
import { language } from "../../../shared/language"

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  themes = [
    {
      value: "default",
      name: "Light",
    },
    {
      value: "dark",
      name: "Dark",
    },
    {
      value: "cosmic",
      name: "Cosmic",
    },
    {
      value: "corporate",
      name: "Corporate",
    },
  ];

  currentTheme = "default";
  selectedItem = String(JSON.parse(localStorage.getItem("language")))
  userMenu = [];

  profileSubscription: Subscription;
  mediaUrl = MEDIA_URL;
  languageJson = language;
  isActiveURL: boolean = false;
  showBadge: boolean = false;
  unseen_messsage: any;
  languageKey: any;
  private languageKeySubscription: Subscription;
  bellSubscription: Subscription;

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private route: ActivatedRoute,
    private themeService: NbThemeService,
    private router: Router,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private cs: CommonService
  ) {
    this.menuService.onItemClick().subscribe((event) => {
      if (event.item.title === "Log out") {
        this.logOut();
      }
    });

    this.profileSubscription = this.cs.updateProfile.subscribe((isUpdate) => {
      if (isUpdate) {
        this.getProfile();
      }
    });

    this.bellSubscription = this.cs.updateBellIcon.subscribe(isUpdate => {
      this.isActiveURL = isUpdate;
    });

  }

  ngOnInit() {
    this.getProfile();
    this.languageKeySubscription = this.cs.getLanguageKey().subscribe(language => {
      this.languageKey = language;
      console.log('Updated language key:', this.languageKey);
    });
    this.currentTheme = this.themeService.currentTheme;
    const { md } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < md),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));
  }
  // selectLanguage(language) {
  //   localStorage.setItem('language', JSON.stringify(language))
  //   let x = this.cs.getLanguageKey()
  //   this.langKey = x
  // }
  selectLanguage(language: any) {
    this.cs.setLanguageKey(language);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { language: language },
      queryParamsHandling: 'merge' // merge with existing query params
    });

  }

  navigateToProfile() {
    this.router.navigate(['/admin/profile']);
  }

  navigateToChangePwd() {
    this.router.navigate(['/admin/change-password']);
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
  navigateNoti() {
    this.cs.updateActiveBell.next(true);
    this.router.navigate(["/admin/notify/listing"]);
  }
  getProfile() {
    this.cs.httpRequest(API_TYPE.GET, "admin/profile", "").subscribe((res) => {
      // console.log(res)
      this.user = res;
      this.user.image = res.image
        ? this.mediaUrl + res.image + "&folder=medium"
        : "";

      this.unseen_messsage = this.showBadge ? res.unread_notifications : 0;
      this.hideShowBadge();
    });
  }

  logOut() {
    this.cs.logOut(1);
  }

  getUserImage(url,) {
    return this.cs.getImgUrl(url, true);
  }

  hideShowBadge() {
    if (this.router.url == '/app/notifications') {
      this.showBadge = false;
    } else {
      this.showBadge = true;
    }
    this.unseen_messsage = this.showBadge ? this.unseen_messsage : 0;
  }

  ngOnDestroy() {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
