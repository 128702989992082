<nb-card>
    <nb-card-header>
        <div class="d-flex justify-content-between align-items-center">
            <div>Email Verification</div>
            <div>
                <button nbButton status="primary" size="small" (click)="LogOut()">Logout</button>
            </div>

        </div>
    </nb-card-header>
    <nb-card-body class="py-md-4">
        <div>
            <p>A verification link has been sent to your email. Please verify your email.</p>
            <div class="d-flex justify-content-between align-items-center mt-4">
                <!-- <button nbButton status="primary" size="small" (click)="Verified()"
                    [disabled]="!userData.is_email_verified">DONE</button> -->
                <button nbButton status="primary" size="small" (click)="Verified()">DONE</button>
                <a class="text-primary font-weight-bold pointer" (click)="ResendLink()">RESEND LINK</a>
            </div>
        </div>
    </nb-card-body>
</nb-card>