import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { catchError, map, timeout } from "rxjs/operators";
import { API_TYPE, BASE_URL, MEDIA_URL } from "../constants";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  categoryData: any;
  updateProfile: Subject<any> = new Subject<any>();
  updateBellIcon: Subject<any> = new Subject<any>();
  updateActiveBell: Subject<any> = new Subject<any>();
  // userEmail: any;
  userEmail = new BehaviorSubject<any>('');
  submitted: boolean;
  cs: any;
  language_key: any
  private languageKeySubject: BehaviorSubject<any> = new BehaviorSubject<any>(JSON.parse(localStorage.getItem("language")) || {});
  constructor(

    private toastr: ToastrService,
    private http: HttpClient,
    private router: Router
  ) { }

  imgUpload(value) {
    return this.http.post(this.getUrl("upload/aws"), value).pipe(
      timeout(60000),
      catchError((err) => this.handleError(err, this)),
      map((res: HttpResponse<any>) => res)
    );
  }
  getLanguageKey(): Observable<any> {
    return this.languageKeySubject.asObservable();
  }
  setLanguageKey(language: any) {
    localStorage.setItem('language', JSON.stringify(language));
    this.languageKeySubject.next(language);
  }
  getUrl(url) {
    return BASE_URL + url;
  }

  navigate(url) {
    this.router.navigateByUrl(url);
  }

  getHeader() {
    var session = JSON.parse(localStorage.getItem("admin-session"));
    const headers = new HttpHeaders()
      .set("token", session ? session.token : "")
      .set("Content-Type", "application/json");

    return headers;
  }
 
  uploadImage(url, data?) {
    var session = JSON.parse(localStorage.getItem("admin-session"));
    const headers = new HttpHeaders()
      .set("token", session ? session.token : "")
    // .set("Content-Type", "multipart/form-data");
    return this.http
      .post(this.getUrl(url), data, { headers })
      .pipe(
        timeout(60000),
        // catchError((err) => this.handleError(err, this)),
        // map((res: HttpResponse<any>) => res)
      );
  }

  httpRequest(type, url, data?): Observable<any> {
    if (type == API_TYPE.GET) {
      return this.http.get(this.getUrl(url), {
        params: data,
        headers: this.getHeader(),
      });
    } else if (type == API_TYPE.POST) {
      return this.http.post(this.getUrl(url), data, { headers: this.getHeader() })
        .pipe(
          timeout(60000),
          catchError((err) => this.handleError(err, this)),
          map((res: HttpResponse<any>) => res)
        );
    } else if (type == API_TYPE.PUT) {
      return this.http
        .put(this.getUrl(url), data, { headers: this.getHeader() })
        .pipe(
          timeout(60000),
          catchError((err) => this.handleError(err, this)),
          map((res: HttpResponse<any>) => res)
        );
    } else if (type == API_TYPE.PATCH) {
      return this.http
        .patch(this.getUrl(url), data, { headers: this.getHeader() })
        .pipe(
          timeout(60000),
          catchError((err) => this.handleError(err, this)),
          map((res: HttpResponse<any>) => res)
        );
    } else if (type == API_TYPE.DELETE) {
      let data1 = {
        body: data,
        headers: this.getHeader(),
      };
      return this.http.delete(this.getUrl(url), data1).pipe(
        timeout(60000),
        catchError((err) => this.handleError(err, this)),
        map((res: HttpResponse<any>) => res)
      );
    }
  }

  handleError(err, that) {
    if (err.status == 401) {
      that.unauthorized();
    } else if (err.status == 0) {
      that.showError("Unkown Error", "Please Check Your Internet Connection");
    } else if (
      err.status == 500 ||
      err.status == 501 ||
      err.status == 502 ||
      err.status == 503 ||
      err.status == 504 ||
      err.status == 505
    ) {
      that.showError("Server Side Error", "Something Went Wrong on Server");
    } else if (err.status == 404) {
      that.showError("Not Found", "Data Missing");
    } else if (err.status == 403) {
      that.showError("Not Allowed", "Not Authorized to access this Page");
    } else {
      if (typeof err.error.error_description == "string") {
        that.showError("", err.error.error_description);
      } else {
        that.showError("", "Something Went Wrong");
      }
    }
    return throwError(err);
  }
  showSuccess(msg, title) {
    this.toastr.success(msg, title, {
      timeOut: 1500,
      positionClass: "toast-top-right",
      progressBar: true,
    });
  }

  showError(msg, title) {
    this.toastr.error(msg, title, {
      timeOut: 2000,
      positionClass: "toast-top-right",
      progressBar: true,
    });
  }
  showWarning(msg, title) {
    this.toastr.warning(msg, title, {
      timeOut: 2000,
      positionClass: "toast-top-right",
      progressBar: true,
    });
  }

  showWentWrong() {
    this.toastr.error("Something Went Wrong", "Error", {
      timeOut: 1500,
      positionClass: "toast-top-right",
      progressBar: true,
    });
  }
  unauthorized() {
    this.toastr.error("Please login to Continue.", "Unauthorized", {
      timeOut: 1500,
      positionClass: "toast-top-right",
      progressBar: true,
    });
    localStorage.clear();
  }

  logout() {
    this.router.navigate(["/"]);
    localStorage.removeItem("session");
    this.showSuccess("User Logged out successfully", "Logged Out");
  }
  Forbidden_logout() {
    this.router.navigate(["/"]);
    localStorage.removeItem("session");
  }


  activateDeactivate(url) {
    return this.http
      .put(this.getUrl(url), "", { headers: this.getHeader() })
      .pipe(
        timeout(60000),
        catchError((err) => this.handleError(err, this)),
        map((res: HttpResponse<any>) => res)
      );
  }

  deleteData(url) {
    return this.http
      .delete(this.getUrl(url), { headers: this.getHeader() })
      .pipe(
        timeout(60000),
        catchError((err) => this.handleError(err, this)),
        map((res: HttpResponse<any>) => res)
      );
  }

  logOutt(id?) {
    localStorage.removeItem('admin-session');
    localStorage.removeItem('device_id');
    // localStorage.setItem("admin-session", JSON.stringify(admin_session));
    //       localStorage.setItem("device_id", JSON.stringify({
    //         'device_id':this.user["device_id"]
    //       }));
    this.router.navigate(["login"]);
  }
  logOut(id?) {
    let device_id = JSON.parse(localStorage.getItem("device_id"));
    const params = {
      device_id: device_id?.device_id,
    };
    this.httpRequest(API_TYPE.POST, "/logout", params).subscribe(
      () => {
        localStorage.clear();
        this.router.navigate(["/login"]);
      },
      (err) => {
        localStorage.clear();
        this.router.navigate(["/login"]);
      }
    );
  }

  // unauthorized() {
  //   this.toastr.error("Please login to Continue.", "Unauthorized", {
  //     timeOut: 1500,
  //     positionClass: "toast-top-right",
  //     progressBar: true,
  //   });
  //   localStorage.clear();
  // }



  getImgUrl(url: any, isUser = false) {
    let output = null;
    if (url) {
      if (url.split("//")[0].includes("http")) {
        output = url;
      } else {
        output = MEDIA_URL + url;
      }
    } else {
      output = `assets/images/${isUser ? "user_placeholder.png" : "placeholder.png"
        }`;
    }
    return output;
  }
}
