import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-delivery-feedback',
  templateUrl: './delivery-feedback.component.html',
  styleUrls: ['./delivery-feedback.component.scss']
})
export class DeliveryFeedbackComponent implements OnInit {
  max = 5;
  rate = 3;
  isReadonly = false;
 
  overStar: number | undefined;
  percent: number;
 
  hoveringOver(value: number): void {
    this.overStar = value;
    this.percent = (value / this.max) * 100;
  }
 
  resetStar(): void {
    this.overStar = void 0;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
